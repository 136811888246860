@import '../../../resources/scss/colors.scss';

.footer {
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: 1.5rem;
  border-top-width: 1px;
  border-top-style: solid;
  padding: calc(0.75rem - 1px) 1rem 0.75rem; // Account for 1px for top border

  @media (min-width: 768px) {
    padding: calc(0.75rem - 1px) 1.5rem 0.75rem; // Account for 1px for top border
  }

  @media (min-width: 1024px) {
    padding: calc(0.75rem - 1px) 2rem 0.75rem; // Account for 1px for top border
  }

  @media (min-width: 1440px) {
    padding: calc(1rem - 1px) 2rem 1rem; // Account for 1px for top border
  }
}

.lightFooter {
  background-color: $white;
  border-top-color: $digital-grey-10;
}

.darkFooter {
  background-color: $core-blue-70;
  border-top-color: $charcoal-40;
}

.footerLogo {
  width: 3.5rem;
  height: 1.25rem;
}
