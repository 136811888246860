@import '../../../resources/scss/colors.scss';
@import '../../../resources/scss/z-indexes.scss';

.searchSuggestionsModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $modal-z-index;
  background-color: $white;
}

.modalDialog {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 0 0 auto;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.modalHeader {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 0 0 auto;
}

.topContent {
  display: flex;
  width: 100%;
  height: 4rem;
}

.stickyTopContent {
  position: absolute;
  top: 0;
}

.modalBody {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 0 0 auto;
  overflow: hidden;
  overflow-y: auto;
}
