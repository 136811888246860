@import '../../../resources/scss/colors.scss';
@import '../../../resources/scss/z-indexes.scss';

.exploreButton {
  display: flex;
  padding: 0.5rem 1rem;
  background-color: $core-blue-60;
  outline: 1px solid $charcoal-30;
  outline-offset: -1px;
  max-height: 2.125rem;

  .exploreButtonIcon {
    height: 0.75rem;
    width: 0.75rem;
  }

  &:hover {
    background-color: $custom-blue-30;
  }

  // Override button styles
  &:focus:not(:focus-visible) {
    outline: 1px solid $charcoal-30;
  }

  &:focus-visible {
    background-color: $custom-blue-30;
    outline: 1px dashed $white;
  }
}

.dropdown {
  display: flex;
}

.dropdownToggle {
  display: flex;
}

.dropdownMenu {
  z-index: $top-nav-dropdown-menu-z-index;
  width: 100%;
  transform: translate3d(0, var(--top-nav-height, 0), 0) !important; // Account for the height of the header. Do not account for the height of the top disclaimer.
}
