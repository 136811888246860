@import '../../../resources/scss/colors.scss';

.textBodyExtraExtraSmallRegularDisabledCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.5rem;
    font-weight: 400;
    line-height: 0.75rem;
    text-align: center;
  }

  @media (min-width: 768px) {

    .value {
      font-size: 0.625rem;
      font-weight: 600;
    }

  }
}

.textBodyExtraExtraSmallRegularSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.5rem;
    font-weight: 400;
    line-height: 0.75rem;
    text-align: center;
  }

  @media (min-width: 768px) {

    .value {
      font-size: 0.625rem;
      font-weight: 600;
    }

  }
}

.textBodyExtraSmallRegularActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-action;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.75rem;
    text-align: left;
  }

}

.textBodyExtraSmallRegularBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(20, 20, 20);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.75rem;
    text-align: left;
  }

}

.textBodyExtraSmallRegularCoreBlue50Left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $core-blue-50;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.75rem; // 12px
    text-align: left;
    letter-spacing: 0.2px;
  }

}

.textBodyExtraSmallRegularInteractionBlue70Left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $interaction-blue-70;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.875rem; // 14px
    text-align: left;
  }

}

.textBodyExtraSmallSemiBoldDisabledCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 0.75rem;
    text-align: center;
  }

}

.textBodyExtraSmallSemiBoldNegativeLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(204, 36, 39);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 0.75rem;
    text-align: left;
  }

}

.textBodyExtraSmallSemiBoldSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 0.75rem;
    text-align: center;
  }

}

.textBodyExtraSmallSemiBoldSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(30, 106, 11);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 0.75rem;
    text-align: left;
  }

}

.textBodyExtraSmallSemiBoldWarningLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(132, 96, 3);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 0.75rem;
    text-align: left;
  }

}

.textBodyLargeRegularActionActiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(1, 78, 116);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }

}

.textBodyLargeRegularActionBaseCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-action;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
  }
}

.textBodyLargeRegularActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-action;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
}

.textBodyLargeRegularActionBaseRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-action;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: right;
  }

}

.textBodyLargeRegularActionHoverLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 101, 151);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }

}

.textBodyLargeRegularBaseDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-base-dark;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
  }
}

.textBodyLargeRegularBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-base-dark;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
}

.textBodyLargeRegularBaseLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $white;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
  }
}

.textBodyLargeRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $white;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
}

.textBodyLargeRegularDisabledCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
  }

}

.textBodyLargeRegularDisabledLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }

}

.textBodyLargeRegularNegativeLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(204, 36, 39);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }

}

.textBodyLargeRegularSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
  }

}

.textBodyLargeRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-subdued-dark;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
}

.textBodyLargeRegularSubduedLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(105, 105, 105);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
  }

}

.textBodyLargeRegularSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-subdued-light;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
}

.textBodyLargeRegularCoreBlue40Center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  .value {
    display: block;
    color: $core-blue-40;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
    text-decoration: none !important;
  }
}

.textBodyLargeSemiBoldActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-action;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
  }

}

.textBodyLargeSemiBoldBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-base-dark;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
  }
}

.textBodyLargeSemiBoldBaseDarkRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-base-dark;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: right;
  }
}

.textBodyLargeSemiBoldBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $white;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
  }
}

.textBodyLargeSemiBoldBaseLightRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $white;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: right;
  }
}

.textBodyLargeSemiBoldBaseLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .value {
    display: block;
    color: $white;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: center;
  }

}

.textBodyLargeSemiBoldDisabledLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
  }

}

.textBodyLargeSemiBoldSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  // MANUAL OVERRIDE STARTS
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  // MANUAL OVERRIDE ENDS

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
  }

}

.textBodyLargeSemiBoldSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(105, 105, 105);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
  }

}

.textBodyMediumRegularActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-action;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }

}

.textBodyMediumRegularActionHoverLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 101, 151);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }

}

.textBodyMediumRegularBaseDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: center; // MANUAL OVERRIDE
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(20, 20, 20);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: center;
  }
}

.textBodyMediumRegularBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-base-dark;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
}

.textBodyMediumRegularBaseLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: center;
  }

}

.textBodyMediumRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $white;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
}

.textBodyMediumRegularBrandLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(16, 37, 63);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }

}

.textBodyMediumRegularCharcoal10Left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $charcoal-10;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
}

.textBodyMediumRegularDisabledLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }

}

.textBodyMediumRegularNegativeLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-negative;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
}

.textBodyMediumRegularPositiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(30, 106, 11);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }

}

.textBodyMediumRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-subdued-dark;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
}

.textBodyMediumRegularSubduedDarkRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-subdued-dark;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: right;
  }
}

.textBodyMediumRegularSubduedLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-subdued-light;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: center;
  }
}

.textBodyMediumRegularSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-subdued-light;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
}

.textBodyMediumSemiBoldDisabledCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.3125rem;
    text-align: center;
  }

}

.textBodyMediumSemiBoldSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.3125rem;
    text-align: center;
  }

}

.textBodyMediumSemiBoldSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-subdued-dark;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.3125rem;
    text-align: left;
  }
}

.textBodySmallRegularActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-action;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: left;
  }

}

.textBodySmallRegularActionBaseCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-action;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: center;
  }

}

.textBodySmallRegularBaseLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: center;
  }

  @media (min-width: 768px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }

  }
}

.textBodySmallRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: left;
  }

}

.textBodySmallRegularCharcoal10Right {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $charcoal-10;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: right;
  }
}

.textBodySmallRegularCharcoal20Left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(139, 147, 158);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: left;
  }
}

.textBodySmallRegularCharcoal30Left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $charcoal-30;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: left;
  }
}

.textBodySmallRegularDisabledCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: center;
  }

  @media (min-width: 768px) {

    .value {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.3125rem;
    }

  }
}

.textBodySmallRegularNegativeLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(204, 36, 39);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: left;
  }

}

.textBodySmallRegularSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: center;
  }

  @media (min-width: 768px) {

    .value {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.3125rem;
    }

  }
}

.textBodySmallRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: left;
  }

}

.textBodySmallRegularSubduedLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-subdued-light;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: center;
  }
}

.textBodySmallRegularSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-subdued-light;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: left;
  }
}

.textBodySmallRegularSubduedLightRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-subdued-light;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: right;
  }
}

.textBodySmallSemiBoldActionLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-action;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: left;
  }

}

.textBodySmallSemiBoldBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: left;
  }

}

.textBodySmallSemiBoldDisabledCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: center;
  }

}

.textBodySmallSemiBoldNegativeLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $interaction-red-60;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: left;
  }
}

.textBodySmallSemiBoldNegativeCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $interaction-red-60;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: center;
  }
}

.textBodySmallSemiBoldPositiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(30, 106, 11);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: left;
  }

}

.textBodySmallSemiBoldSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: center;
  }

}

.textBodySmallSemiBoldSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: left;
  }

}

.textBodySmallSemiBoldWarningLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(132, 96, 3);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: left;
  }

}

.textBodySmallSemiBoldCustomYellow10Left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $custom-yellow-10;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: left;
  }

}

.textBodySmallSemiBoldInteractionRed60Left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $interaction-red-60;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: left;
  }

}

.textButtonTextMediumRegularActionActiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(1, 78, 116);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }

}

.textButtonTextMediumRegularActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(17, 125, 180);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }

}

.textButtonTextMediumRegularActionHoverLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 101, 151);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }

}

.textButtonTextMediumRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $white;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }
}

.textButtonTextMediumRegularBaseLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  .value {
    display: block;
    color: $white;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: center;
  }
}

.textButtonTextMediumRegularDisabledLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }

}

.textButtonTextMediumRegularUnderlineActionActiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(1, 78, 116);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }

}

.textButtonTextMediumRegularUnderlineActionHoverLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 101, 151);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }

}

.textCardBodyLargeRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }

}

.textCardBodyMediumRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $white;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
}

.textCardBodyMediumRegularBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-base-dark;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
}

.textCardBodyMediumRegularPositiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(30, 106, 11);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }

}

.textCardBodyMediumRegularSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-subdued-light;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
}

.textCardBodyMediumRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-subdued-dark;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
}

.textCardBodyMediumRegularCharcoal10Left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $charcoal-10;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
}

.textCardBodySmallRegularSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(105, 105, 105);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: left;
  }

}

.textContactBodyLargeRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }

}

.textContactBodyLargeSemiBoldSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
  }

}

.textDecorationLargeRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 101, 151);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }

  @media (min-width: 768px) {

    .value {
      color: rgb(61, 61, 61);
    }

  }
}

.textDecorationLargeRegularSubduedDarkRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-subdued-dark;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: right;
  }
}

.textDecorationMediumRegularActionActiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(1, 78, 116);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }

}

.textDecorationMediumRegularActionHoverLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 101, 151);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }

}

.textDecorationMediumRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }

}

.textDecorationMediumSemiBoldBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $white;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
  }
}

.textDecorationMediumSemiBoldSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-subdued-dark;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
  }
}

.textDecorationSmallRegularActionActiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(1, 78, 116);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }

}

.textDecorationSmallRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }

}

.textMLBCardTitleMediumSemiBoldBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.25rem;
    text-align: left;
  }

}

.textNewBannerBodyMediumRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }

  @media (min-width: 1440px) {
    .value {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }
}

.textBannerHeadingSmallSemiBoldActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-action;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.875rem;
    text-align: left;
  }

  @media (min-width: 768px) {

    .value {
      font-size: 2rem;
      line-height: 2.625rem;
    }

  }
}

.textBannerSubheadingLargeLightBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2.25rem;
    text-align: left;
  }

}

.textHeadingSmallLightBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-base-dark;
    font-family: 'Optimist', 'sans-serif';
    font-size: 2rem;
    font-weight: 300;
    line-height: 2.625rem;
    text-align: left;
  }
}

.textHeadingSmallRegularBaseLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem; // MANUAL OVERRIDE
    font-weight: 400;
    line-height: 2.625rem;
    text-align: center;
  }

  // MANUAL OVERRIDE STARTS
  @media (min-width: 768px) {
    .value {
      font-size: 2rem;
      line-height: 2.625rem;
    }

  }

  // MANUAL OVERRIDE ENDS
}

.textHeadingSmallRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.625rem;
    text-align: left;
  }

}

.textNewBannerHeadingMediumRegularBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(20, 20, 20);
    font-family: 'Optimist', 'sans-serif';
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.625rem;
    text-align: left;
  }
}

.textNewBannerHeadingMediumRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $white;
    font-family: 'Optimist', 'sans-serif';
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.625rem;
    text-align: left;
  }
}

.textNewEventDetailsTitleMediumSemiBoldBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: left;
  }

  @media (min-width: 768px) {

    .value {
      line-height: 1.875rem;
    }

  }
}

.textSubheadingLargeLightSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2.25rem;
    text-align: center;
  }

}

.textSubheadingLargeLightSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2.25rem;
    text-align: left;
  }

}

.textSubheadingLargeRegularBaseLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.25rem;
    text-align: center;
  }

  @media (min-width: 768px) {

    .value {
      font-size: 2rem;
      line-height: 2.625rem;
    }

  }
}

.textSubheadingLargeRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.25rem;
    text-align: left;
  }

  @media (min-width: 768px) {

    .value {
      font-size: 2rem;
      line-height: 2.625rem;
    }

  }
}

.textSubheadingLargeRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.25rem;
    text-align: left;
  }

}

.textSubheadingLargeRegularSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(105, 105, 105);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.25rem;
    text-align: left;
  }

}

.textSubheadingLargeSemiBoldActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-action;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.25rem;
    text-align: left;
  }

}

.textSubheadingLargeSemiBoldBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $white;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.25rem;
    text-align: left;
  }
}

.textSubheadingMediumLightSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.875rem;
    text-align: left;
  }

}

.textSubheadingMediumLightSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(105, 105, 105);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }

}

.textSubheadingMediumLightSubduedLightRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(105, 105, 105);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: right;
  }

}

.textSubheadingMediumRegularActionActiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(1, 78, 116);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }

}

.textSubheadingMediumRegularActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(17, 125, 180);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }

}

.textSubheadingMediumRegularActionHoverLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 101, 151);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }

}

.textSubheadingMediumRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }

  @media (min-width: 768px) {

    .value {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }

  }
}

.textSubheadingMediumRegularDisabledLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }

}

.textSubheadingMediumRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }

  @media (min-width: 768px) {

    .value {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }

  }
}

.textSubheadingMediumRegularSubduedDarkRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: right;
  }

}

.textSubheadingMediumRegularUnderlineActionHoverLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 101, 151);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }

}

.textSubheadingMediumSemiBoldActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(17, 125, 180);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.875rem;
    text-align: left;
  }
}

.textSubheadingMediumSemiBoldBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $white;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.875rem;
    text-align: left;
  }
}

.textSubheadingMediumSemiBoldBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-base-dark;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.875rem;
    text-align: left;
  }
}

.textSubheadingMediumSemiBoldSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-subdued-dark;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.875rem;
    text-align: left;
  }
}

.textSubheadingSmallRegularBaseLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: center;
  }

  @media (min-width: 768px) {

    .value {
      line-height: 2rem;
      text-align: left;
    }

  }

  @media (min-width: 1024px) {

    .value {
      line-height: 1.875rem;
      text-align: center;
    }

  }
}

.textSubheadingSmallRegularSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-subdued-dark;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: center;
  }
}

.textSubheadingSmallRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }

}

.textSubheadingExtraSmallRegularSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-subdued-light;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
  }
}

.textSubheadingExtraSmallRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-subdued-dark;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
  }
}

.textBannerSubheadingLargeRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 2rem;
    font-weight: 300;
    line-height: 2.625rem;
    text-align: left;
  }

}

.textHeadingSmallLightBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 2rem;
    font-weight: 300;
    line-height: 2.625rem;
    text-align: left;
  }

}

.textHeadingSmallSemiBoldActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-action;
    font-family: 'Optimist', 'sans-serif';
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.625rem;
    text-align: left;
  }

}

.textHeadingSmallSemiBoldBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.625rem;
    text-align: left;
  }

}

.textBodyMediumRegularSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-subdued-dark;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: center;
  }
}

.textBodyMediumSemiBoldBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $white;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.3125rem;
    text-align: left;
  }
}

.textBodyMediumSemiBoldBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-base-dark;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.3125rem;
    text-align: left;
  }
}

.textNewBannerHeadingLargeRegularBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(20, 20, 20);
    font-family: 'Optimist', 'sans-serif';
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.625rem;
    text-align: left;
  }

  @media (min-width: 1440px) {
    .value {
      font-size: 3rem;
      line-height: 3.875rem;
    }
  }
}

.textNewBannerHeadingLargeRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.625rem;
    text-align: left;
  }

  @media (min-width: 1440px) {
    .value {
      font-size: 3rem;
      line-height: 3.875rem;
    }
  }
}

.textNewEventDetailsTitleLargeSemiBoldSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-subdued-dark;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.25rem;
    text-align: left;
  }
}

.textNewEventDetailsTitleLargeSemiBoldSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-subdued-light;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.25rem;
    text-align: left;
  }
}

.textNewEventDetailsTitleLargeSemiBoldBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.25rem;
    text-align: left;
  }

}

.textSubheadingSmallRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $white;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }
}

.textSubheadingSmallRegularBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: $text-base-dark;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }
}

// MANUAL OVERRIDE STARTS
.textBodyMediumRegularActionBaseCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;

  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: center;
    color: $text-action;
  }

}

// MANUAL OVERRIDE STARTS
.textBodyLargeSemiBoldActionBaseCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;

  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: center;
    color: $text-action;
  }

}

// MANUAL OVERRIDE ENDS

.textBodyLargeRegularUnderlineActionHoverLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 101, 151);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    text-decoration: underline;
  }

}

// MANUAL OVERRIDE ENDS
