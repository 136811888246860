@import '../../../resources/scss/colors.scss';

$border: 1px solid $digital-grey-10;
$max-width-desktop: 55.5rem;
$fallback-height: 0rem;

.orderConfirmationPage {
  display: flex;
  align-self: unset !important; // Override main layout styles
  width: 100%;

  .column {
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .withWrap {
    flex-wrap: wrap;
  }
}

.contentContainer {
  flex-grow: 1;
  width: 100%;

  @media (min-width: 1024px) {
    max-width: $max-width-desktop;
    border-left: $border;
    border-right: $border;
    box-sizing: content-box;
    // Set an explicit left margin (not 'auto') so that scrollbar does not shift the content
    // Use 100vw instead of 100% because 100vw is no affected by scrollbar
    margin-left: calc((100vw - #{$max-width-desktop}) / 2); // Half of the remaining screen width
    margin-right: auto;
  }
}

.content {
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;

  @media (min-width: 1024px) {
    flex-direction: unset;
  }

  .leftContent {
    width: 100%;
  }

  .rightContent {
    width: 100%;

    @media (min-width: 1024px) {
      max-width: calc(#{$max-width-desktop} / 2);
      border-left: $border;
    }
  }
}

.sectionContent {
  gap: 0.5rem;
  padding: 1rem 1rem calc(1rem - 1px) 1rem; // Account for 1px for bottom border
  border-bottom: $border;
}

.seatingInfo {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.75rem;
  border-radius: 0.5rem;
  background-color: $grey-10;

  .availableWidthItem {
    flex-grow: 1;
  }

  .fixedWidthItem {
    min-width: 3.75rem;
  }
}

.messageContent {
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem calc(1rem - 1px) 1rem; // Account for 1px for bottom border
  border-bottom: $border;
  gap: 0.5rem;
  background-color: $interaction-green-10;
}

.orderDetailsContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0;
  padding: 1rem 1rem calc(1rem - 1px) 1rem; // Account for 1px for bottom border
  border-bottom: $border;

  .printButton {
    height: fit-content;
    text-decoration: underline;
    text-decoration-color: $text-action;

    p {
      color: $text-action;
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
  }
}

.accountCardImage {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1rem;
  margin: 0.25rem 0;
  border-radius: 1px;
}

.deliveryNotes {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0;
  margin: 0;

  li {
    list-style-type: disc;
    line-height: 1.3125rem;
    margin: 0 0 0 1.5rem;

    &::marker {
      // Marker's line height must be less than li's line height.
      // Otherwise, li's height will be 1px more than expected.
      line-height: 1rem;
    }
  }
}
