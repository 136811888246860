@import '../../../resources/scss/colors.scss';

.highlightedMessage {
  display: flex;
  gap: 0.5rem;
  padding: 0.75rem;
  border-left-width: 0.25rem;
  border-left-style: solid;
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;

  &.infoHighlightedMessage {
    background-color: $accent-yellow;
    border-left-color: $custom-yellow-70;
  }

  &.errorHighlightedMessage {
    background-color: $interaction-red-10;
    border-left-color: $interaction-red-50;
  }

  .icon {
    width: 1.5rem;
    height: calc(1.5rem - 1px);
    margin-top: -0.125rem;
  }
}
