@import '../../../resources/scss/colors.scss';

.exclusiveBadge {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem 0.75rem 0.25rem 0.5rem;
  border-radius: 6.25rem;
  box-shadow: 0 0 1px 0 #00000014;
  width: fit-content;
  box-sizing: border-box;
}

.lightExclusiveBadge {
  background-color: $white;
}

.darkExclusiveBadge {
  background-color: $interaction-blue-10;
}

.image {
  width: 1rem;
  height: 1rem;
}
