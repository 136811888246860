@import '../../../resources/scss/colors.scss';
@import '../../../resources/scss/z-indexes.scss';

$fallback-height: 0rem;
$border-color: $digital-grey-10;

$max-content-width-desktop: 60rem;
$max-content-width-desktop-1440px: 84rem;

$padding-x-mobile: 1rem;
$padding-x-tablet: 2rem;
$padding-x-desktop: 2rem;
$padding-x-desktop-1440px: 3rem;

$carousel-items-gap-mobile: 1rem;
$carousel-items-gap-tablet: 1.5rem;
$carousel-items-gap-desktop: 1.5rem;
$carousel-items-gap-desktop-1440px: 2rem;

.homePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
}

.container {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.darkContainer {
  background-color: $core-blue-70;
}

// This class to be applied to non-carousel sections
.subContainer {
  padding-left: $padding-x-mobile;
  padding-right: $padding-x-mobile;

  @media (min-width: 768px) {
    padding-left: $padding-x-tablet;
    padding-right: $padding-x-tablet;
  }
}

// This CSS is applied only on desktops
.subContainer,
.carouselContainer {
  @media (min-width: 1024px) {
    width: calc(#{$max-content-width-desktop} + 2 * #{$padding-x-desktop}); // Account for 2 x 2rem for left and right paddings
    padding-left: $padding-x-desktop;
    padding-right: $padding-x-desktop;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 1440px) {
    width: calc(#{$max-content-width-desktop-1440px} + 2 * #{$padding-x-desktop-1440px}); // Account for 2 x 3rem for left and right paddings
    padding-left: $padding-x-desktop-1440px;
    padding-right: $padding-x-desktop-1440px;
  }
}

.carouselContainer {
  z-index: calc(#{$filters-container-z-index} - 1);
}

// For light sections of the page on 1024px we need to show grey left and right borders on containers
.withVerticalBorders {

  // Add 1px to 1024px so that left and right borders are not shown on exactly 1024px
  @media (min-width: 1025px) and (max-width: 1439px) {
    padding-left: calc(#{$padding-x-desktop} - 1px); // Account for 1px for left border
    padding-right: calc(#{$padding-x-desktop} - 1px); // Account for 1px for right border
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
  }

  // Add 1px to 1440px so that left and right borders are not shown on exactly 1440px
  @media (min-width: 1441px) {
    padding-left: calc(#{$padding-x-desktop-1440px} - 1px); // Account for 1px for left border
    padding-right: calc(#{$padding-x-desktop-1440px} - 1px); // Account for 1px for right border
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
  }
}

// This CSS is applied to carousel header only on mobiles where carousel items scroll from edge to edge of the screen without any outer paddings.
// In this case the entire carousel section does not have left and right paddings.
// But carousel header needs to have left and right paddings on mobiles
// So we add left and right paddings here.
.mobileCarouselHeader {
  padding-left: $padding-x-mobile;
  padding-right: calc(#{$padding-x-mobile} - 0.5rem);

  @media (min-width: 768px) {
    padding-left: $padding-x-tablet;
    padding-right: $padding-x-tablet;
  }

  // On 1024px class .carouselContainer will apply which will set left and right paddings around the entire carousel section (see above).
  // Hence we clear left and right paddings around carousel header here.
  @media (min-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.cardholderExclusiveEventsCarouselHeader {
  @media (min-width: 1440px) {
    padding-top: 2rem;
  }
}

// Increase CSS specificity
.cardholderExclusiveEventsCarouselTitle.cardholderExclusiveEventsCarouselTitle h2 {
  @media (min-width: 1024px) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}

// This CSS is applied to carousel items container.
// Note about left and right paddings:
// On mobiles carousel items scroll from edge to edge of the screen without any outer left and right paddings.
// In this case the entire carousel section does not have left and right paddings.
// But carousel items container needs to have left and right paddings on mobiles so that we show a padding before the first item and also after the last item
// So we add left and right paddings here.
.carouselItemsContainer {
  gap: $carousel-items-gap-mobile;
  padding-left: $padding-x-mobile;
  padding-right: $padding-x-mobile;

  @media (min-width: 768px) {
    gap: $carousel-items-gap-tablet;
    padding-left: $padding-x-tablet;
    padding-right: $padding-x-tablet;
  }

  // On 1024px class .carouselContainer will apply which will set left and right paddings around the entire carousel section (see above).
  // Hence we clear left and right paddings around carousel items container here.
  @media (min-width: 1024px) {
    gap: $carousel-items-gap-desktop;
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: 1440px) {
    gap: $carousel-items-gap-desktop-1440px;
  }
}

.carouselItem {
  // On mobiles we need to show carousel items with a fixed width of 16rem
  min-width: 16rem;
  max-width: 16rem;

  // On 1024px we need to show 3 carousel items with a filled width
  @media (min-width: 1024px) {
    min-width: calc((100% - 2 * #{$carousel-items-gap-desktop}) / 3); // Account for 2 x 1.5rem for gaps
    max-width: calc((100% - 2 * #{$carousel-items-gap-desktop}) / 3); // Account for 2 x 1.5rem for gaps
  }

  // On 1440px we need to show 4 carousel items with a filled width
  @media (min-width: 1440px) {
    min-width: calc((100% - 3 * #{$carousel-items-gap-desktop-1440px}) / 4); // Account for 3 x 2rem for gaps
    max-width: calc((100% - 3 * #{$carousel-items-gap-desktop-1440px}) / 4); // Account for 3 x 2rem for gaps
  }
}

.performersCarouselItemsContainer {
  @media (max-width: 767px) {
    gap: 0.75rem;
  }
}

.performersCarouselItem {
  @media (max-width: 767px) {
    min-width: 10rem;
    max-width: 10rem;
  }
}

.eventsCarouselLoadingOrEmptyStateContainer {
  height: 21.875rem;
}

.performersCarouselLoadingOrEmptyStateContainer {
  height: 12rem;

  @media (min-width: 768px) {
    height: 15.5rem;
  }

  @media (min-width: 1024px) {
    height: 19rem;
  }
}

.filtersContainer {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding-top: 1rem;
  padding-bottom: calc(1rem - 1px); // Account for 1px for bottom border
  border-bottom: 1px solid $border-color;
  background-color: #FFFFFFE5;
  backdrop-filter: blur(0.625rem);
  z-index: $filters-container-z-index;

  // Make filter bar sticky on mobiles
  @media (max-width: 1023px) {
    position: -webkit-sticky;
    position: sticky;
    top: calc(var(--top-fixed-height, #{$fallback-height})); // Account for the height of the top fixed content (TopNav and TopDisclaimer)
  }
}

.filterHint p {
  text-wrap: nowrap;
}

// Increase CSS specificity
.regionFilter.regionFilter.regionFilter {
  width: unset;
  min-width: 10.125rem;
  box-shadow: 0 1px 0.25rem 0 #00000033;

  .filterToggleWrapper {
    background-color: $white;
  }

  .filterToggle {
    padding: 0.25rem 0.5rem 0.25rem 0.75rem;
  }

  .displayValue {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .filterIcon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.bannerContainer {
  padding: 0 0 1rem 0;

  @media (min-width: 768px) {
    padding: 0.5rem 0 1.5rem 0;
  }

  @media (min-width: 1024px) {
    padding: 0.5rem 0 2rem 0;
  }

  @media (min-width: 1440px) {
    padding: 1rem 0 2rem 0;
  }

  .banner {
    height: unset;
    padding: 1rem;
    border-radius: 0.75rem;
    box-shadow: 0 0.125rem 0.5rem 0 #00000033;
    box-sizing: border-box;

    &::before {
      background: #00132B80;
    }

    @media (min-width: 768px) {
      padding: 1.5rem 1.5rem 3.875rem 1.5rem;
    }

    @media (min-width: 1024px) {
      padding: 2.375rem 2rem;

      &::before {
        background: #00132B66;
      }
    }
  }

  .content {
    padding: 0;
    gap: 0.25rem;
  }

  .title p {
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-weight: 400;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 2rem;
      line-height: 2.625rem;
    }
  }

  .description {
    @media (min-width: 768px) {
      width: 33.375rem;
      height: 3rem;
    }
  }

  .buttonContainer {
    height: 2.625rem;
    margin-top: 0.75rem;
    padding: 0 1.5rem;

    &:focus-visible {
      outline: 0.125rem dashed $interaction-blue-50;
      outline-offset: -0.125rem;
    }
  }

  .buttonText p {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: center;
    color: $interaction-blue-50;
  }
}

.cardholderExclusivesBannerContainer {
  padding: 1rem 0;

  @media (min-width: 768px) {
    padding: 1.5rem 0;
  }

  @media (min-width: 1440px) {
    padding: 2rem 0;
  }

  .cardholderExclusivesBanner {
    @media (min-width: 768px) {
      &::before {
        background: #00000080;
      }
    }
  }
}
