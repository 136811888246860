@import '../../../resources/scss/colors.scss';
@import '../../../resources/scss/z-indexes.scss';

$border-radius: 1.5rem;

.filter,
.filterToggleWrapper {
  display: flex;
  width: 100%;
  border-radius: $border-radius;

  @media (min-width: 320px) {
    min-width: 8.5rem;
  }

  @media (min-width: 768px) {
    max-width: 12rem;
  }
}

// Increase CSS specificity
.filterButton.filterButton {
  background-color: transparent;

  &:disabled {
    .displayValue {
      p {
        opacity: 50%;
      }
    }

    .filterIcon {
      opacity: 50%;
    }
  }
}

.filterToggle {
  flex-grow: 1;
  overflow: hidden;
  padding: 0.5rem 0.75rem 0.5rem 1rem;
  border-radius: $border-radius;

  &.split {
    padding: 0.5rem 0.25rem 0.5rem 1rem;
    border-radius: $border-radius 0 0 $border-radius;
  }
}

.filterToggleContent {
  justify-content: space-between !important; // Override CSS of the Button component
  flex-grow: 1;
  overflow: hidden;
}

.filterToggleWrapper {
  .displayValue {
    width: 100%;
    overflow: hidden;

    p {
      line-height: 1.25rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.clearFilterButton {
  padding: 0.5rem 0.75rem 0.5rem 0.25rem;
  border-radius: 0 $border-radius $border-radius 0;
  width: unset;
  height: unset;
  box-sizing: border-box;
}

// Increase CSS specificity
.filterIcon.filterIcon {
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  overflow: visible;
}

.dropdownMenuBase {
  border-radius: 0.75rem;
  box-shadow: 0 0.125rem 0.5rem 0 #00000033;
  z-index: $dropdown-menu-z-index;
}

.dropdownMenu {
  background-color: $white;
  width: 100%;
  max-width: 20.5rem;
  max-height: 29.5rem;
  padding: 0.75rem 0;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.dropdownMenuItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  padding: 1.25rem 1.5rem;
  box-sizing: border-box;

  &:hover {
    background-color: $interaction-blue-10;
  }

  &.selected {

    // Reduce width to account for the green checkmark icon
    .dropdownMenuItemContent {
      width: calc(100% - 0.75rem - 1.5rem); // Account for 0.75rem gap and 1.5rem green checkmark icon
    }
  }
}

.dropdownMenuItemContent {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
}

.dropdownMenuItemIcon {
  display: flex;
  flex-shrink: 0;
}

.dropdownMenuItemText {
  overflow: hidden;

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.checkMarkIcon {
  display: flex;
  flex-shrink: 0;
}

.light {
  .filterToggleWrapper {
    background-color: $grey-10;

    &:hover:not([disabled]),
    &.highlighted:not([disabled]) {
      background-color: $interaction-blue-10;
    }
  }

  .filterButton {
    &:focus-visible:not(:disabled) {
      background-color: $interaction-blue-10;
      outline: 0.125rem dashed $interaction-blue-50;
      outline-offset: -0.125rem;
    }
  }

  .split,
  .clearFilterButton {
    &:hover:not(:disabled) {
      outline: 1px solid $interaction-blue-50;
      outline-offset: -1px;
    }
  }
}

.dark {
  .filterToggleWrapper {
    background-color: $core-blue-60;
    outline: 1px solid $charcoal-40;
    outline-offset: -1px;

    &:hover:not([disabled]),
    &.highlighted:not([disabled]) {
      background-color: $custom-blue-30;
    }

    // Undo outline if child elements show their own outlines (see below)
    &:has(.filterButton:focus-visible:not(:disabled)),
    &:hover:not(:disabled):has(.split),
    &:hover:not(:disabled):has(.clearFilterButton) {
      outline: none;
    }
  }

  .filterButton {
    &:focus-visible:not(:disabled) {
      background-color: $custom-blue-30;
      // Make sure that outline for the parent .filterToggleWrapper class is undone
      outline: 0.125rem dashed $white;
      outline-offset: -0.125rem;
    }
  }

  .split,
  .clearFilterButton {
    &:hover:not(:disabled) {
      // Make sure that outline for the parent .filterToggleWrapper class is undone
      outline: 1px solid $white;
      outline-offset: -1px;
    }
  }
}
