@import '../../../resources/scss/colors.scss';

.section {
  padding: 1rem 1rem calc(1rem - 1px) 1rem; // Account for 1px for bottom border
  border-bottom: 1px solid $digital-grey-10;

  .selectContainer p {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.3125rem;
  }
}
