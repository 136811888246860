@import '../../../resources/scss/colors.scss';

.mainLayout {
  display: flex;
  flex-direction: column;

  .page {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex: 1 1 auto;
  }
}

.topFixedSpacer {
  width: 100%;
  min-height: var(--top-fixed-height, 0);
  background-color: $core-blue-70;
}
