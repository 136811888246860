@import '../../../resources/scss/colors.scss';

.iconArenaBaseDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $text-base-dark;
  }

}

.iconArenaWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $white;
  }

}

.iconArrowLeftActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(2, 118, 177);
  }

}

.iconArrowLeftBaseDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $text-base-dark;
  }

}

.iconArrowLeftWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $white;
  }

}

.iconArrowLeftSubduedDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $text-subdued-dark;
  }
}

.iconArrowRightFilledActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(2, 118, 177);
  }

}

.iconArrowRightFilledActionHover {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(2, 101, 151);
  }

}

.iconArrowRightFilledWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $white;
  }

}

.iconArrowRightActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $button-action-base;
  }
}

.iconArrowRightWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $white;
  }

}

.iconCalendarIllustrationDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content6], [id=Content6] path {
    display: block;
    fill: rgb(37, 95, 130);
  }

  path[id=Content5], [id=Content5] path {
    display: block;
    fill: $white;
  }

  path[id=Content4], [id=Content4] path {
    display: block;
    fill: rgb(192, 231, 243);
  }

  path[id=Content3], [id=Content3] path {
    display: block;
    fill: rgb(1, 61, 88);
  }

  path[id=Content2], [id=Content2] path {
    display: block;
    fill: rgb(120, 208, 235);
  }

  path[id=Content1], [id=Content1] path {
    display: block;
    fill: rgb(249, 198, 6);
  }

}

.iconCalendarLargeDigitalGrey30 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(168, 168, 168);
  }

}

.iconCalendarActionActive {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(2, 101, 151);
  }

}

.iconCalenderNewSubduedLight {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $text-subdued-light;
  }
}

.iconCalenderNewSubduedDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $text-subdued-dark;
  }
}

.iconCalenderNewCharcoal10 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $charcoal-10;
  }
}

.iconCheckboxFilledBaseDark {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    fill: $text-subdued-dark;
    stroke: $text-subdued-dark;
  }
}

.iconCheckboxFilledActionBase {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    fill: $button-action-base;
    stroke: $button-action-base;
  }
}

.iconCheckboxFilledError {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    fill: $button-negative-base;
    stroke: $button-negative-base;
  }
}

.iconCheckboxEmptyBaseDark {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    stroke: $text-subdued-dark;
  }
}

.iconCheckboxEmptyActionBase {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    stroke: $button-action-base;
  }
}

.iconCheckboxEmptyError {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    stroke: $button-negative-base;
  }
}

.iconCheckmarkInCircleFilledGreen60 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(30, 106, 11);
  }

}

.iconCheckmarkInCircleFilledBaseLight {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $white;
  }
}

.iconCheckmarkInCircleDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(61, 61, 61);
  }

}

.iconCheckmarkInCircleSubduedDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $text-subdued-dark;
  }
}

.iconCheckmarkActionActive {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(1, 78, 116);
  }

}

.iconCheckmarkActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(2, 118, 177);
  }

}

.iconCheckmarkDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(61, 61, 61);
  }

}

.iconCheckmarkWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $white;
  }

}

.iconCheckmarkBaseDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $text-base-dark;
  }
}

.iconChevronDownFilledDigitalGrey100 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $digital-grey-100;
  }

}

.iconChevronDownActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $button-action-base;
  }
}

.iconChevronDownActionActive {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(1, 78, 116);
  }

}

.iconChevronDownActionHover {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(2, 101, 151);
  }

}

.iconChevronDownDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(61, 61, 61);
  }

}

.iconChevronDownDigitalGrey100 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $digital-grey-100;
  }

}

.iconChevronDownSubduedDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $text-subdued-dark;
  }

}

.iconChevronDownWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $white;
  }

}

.iconChevronDownCharcoal10 {
  overflow: hidden;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $charcoal-10;
  }
}

.iconChevronLeftFilledDigitalGrey100 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: $digital-grey-100;
  }

}

.iconChevronLeftActionActive {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(1, 78, 116);
  }

}

.iconChevronLeftActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(2, 118, 177);
  }

}

.iconChevronLeftActionHover {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(2, 101, 151);
  }

}

.iconChevronLeftDigitalGrey100 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $digital-grey-100;
  }

}

.iconChevronLeftSubduedDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $text-subdued-dark;
  }

}

.iconChevronLeftWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $white;
  }

}

.iconChevronRightFilledDigitalGrey100 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: $digital-grey-100;
  }

}

.iconChevronRightActionActive {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(2, 101, 151);
  }

}

.iconChevronRightActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(2, 118, 177);
  }

}

.iconChevronRightDigitalGrey100 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $digital-grey-100;
  }

}

.iconChevronRightWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $white;
  }

}

.iconChevronRightSubduedDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $text-subdued-dark;
  }

}

.iconChevronUpFilledDigitalGrey100 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: $digital-grey-100;
  }

}

.iconChevronUpActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $button-action-base;
  }

}

.iconChevronUpActionActive {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(1, 78, 116);
  }

}

.iconChevronUpActionHover {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(2, 101, 151);
  }

}

.iconChevronUpDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(61, 61, 61);
  }

}

.iconChevronUpDigitalGrey100 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $digital-grey-100;
  }

}

.iconChevronUpSubduedDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $text-subdued-dark;
  }

}

.iconChevronUpWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $white;
  }

}

.iconClockLargeDigitalGrey60 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(105, 105, 105);
  }

}

.iconClockBaseDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: $text-base-dark;
  }

}

.iconCloseCircleFillDigitalGrey100 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $digital-grey-100;
  }

}

.iconCloseCircleActionHover {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(2, 101, 151);
  }

}

.iconCloseCircleDigitalGrey100 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(61, 61, 61);
  }

}

.iconCloseCircleWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $white;
  }

}

.iconCloseActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(2, 118, 177);
  }

}

.iconCloseDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(61, 61, 61);
  }

}

.iconCloseWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $white;
  }

}

.iconCloseSubduedLight {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $text-subdued-light;
  }
}

.iconCloseSubduedDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $text-subdued-dark;
  }
}

.iconCloseBaseDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $text-base-dark;
  }
}

.iconDeliveryDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(61, 61, 61);
  }

}

.iconExploreWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content],
  [id=Content] path {
    display: block;
    fill: $white;
  }

}

.iconFootballDigitalGrey30 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(168, 168, 168);
  }

}

.iconInfoFilledActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $button-action-base;
  }
}

.iconInfoFilledDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $text-base-dark;
  }
}

.iconInfoDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(20, 20, 20);
  }

}

.iconInfoWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $white;
  }
}

.iconLocationMarkerSmallWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: $white;
  }

}

.iconLocationMarkerActionActive {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(2, 101, 151);
  }

}

.iconLocationPinFilledSubduedLight {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $text-subdued-light;
  }
}

.iconLocationPinFilledSubduedDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $text-subdued-dark;
  }
}

.iconLocationPinFilledCharcoal10 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $charcoal-10;
  }
}

.iconLocationPinActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(2, 118, 177);
  }

}

.iconLocationPinDigitalGrey100 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $digital-grey-100;
  }

}

.iconLocationPinSubduedDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $text-subdued-dark;
  }

}

.iconLocationPinWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $white;
  }

}

.iconMailDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(61, 61, 61);
  }

}

.iconMasterCardDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.95rem;
  height: 1.1875rem;
}

.iconMenuWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: $white;
  }

}

.iconMinusActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: rgb(2, 118, 177);
  }

}

.iconMinusDigitalGrey60 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=A], [id=A] path {
    display: block;
    fill: rgb(108, 114, 117);
  }

}

.iconMobileScanDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(61, 61, 61);
  }

}

.iconMobileDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(61, 61, 61);
  }

}

.iconMobileCharcoal10 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $charcoal-10;
  }
}

.iconMyLocationActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(2, 118, 177);
  }

}

.iconMyLocationDigitalGrey100 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $digital-grey-100;
  }

}

.iconMyLocationSubduedDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $text-subdued-dark;
  }

}

.iconMyLocationWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $white;
  }

}

.iconPlusActionActive {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(37, 95, 130);
  }

}

.iconPlusActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(2, 118, 177);
  }

}

.iconPlusDigitalGrey60 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(105, 105, 105);
  }

}

.iconPrinterActionActive {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(1, 78, 116);
  }

}

.iconPrinterActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(2, 118, 177);
  }

}

.iconProfileDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(61, 61, 61);
  }

}

.iconProfileCharcoal10 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $charcoal-10;
  }
}

.iconSearchSmallCharcoal20 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: rgb(139, 147, 158);
  }

}

.iconSearchSmallWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $white;
  }

}

.iconSearchBaseDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $text-base-dark;
  }

}

.iconSettingsSubduedLight {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $text-subdued-light;
  }
}

.iconSettingsSubduedDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $text-subdued-dark;
  }
}

.iconShirtBaseDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $text-base-dark;
  }

}

.iconShirtWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path {
    display: block;
    fill: $white;
  }

}

.iconSparklesYellow {
  overflow: hidden;
  display: flex;
  width: 1rem;
  height: 1rem;
}

.iconThumbtackDigitalGrey30 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=E], [id=E] path {
    display: block;
    fill: rgb(188, 193, 194);
  }

}

.iconTicketBigDigitalGrey30 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(168, 168, 168);
  }

}

.iconTicketCutDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(61, 61, 61);
  }

}

.iconTicketDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(61, 61, 61);
  }

}

.iconTicketCharcoal10 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $charcoal-10;
  }
}

.iconTicketWithLinesDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(68, 70, 73);
  }

}

.iconTicketWithLinesSubduedDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.125rem;
  box-sizing: border-box;  

  path {
    display: block;
    fill: $text-subdued-dark;
  }
}

.iconTicketWithLinesCharcoal10 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.125rem;
  box-sizing: border-box;

  path {
    display: block;
    fill: $charcoal-10;
  }
}

.iconTrophySubduedDark {
  overflow: hidden;
  display: flex;
  width: 0.75rem;
  height: 0.75rem;
  padding: 0.125rem;
  box-sizing: border-box;

  path {
    display: block;
    fill: $text-subdued-dark;
  }
}

.iconTrophyCharcoal10 {
  overflow: hidden;
  display: flex;
  width: 0.75rem;
  height: 0.75rem;
  padding: 0.125rem;
  box-sizing: border-box;

  path {
    display: block;
    fill: $charcoal-10;
  }
}

.iconTrophyCustomBlue20 {
  overflow: hidden;
  display: flex;
  width: 0.75rem;
  height: 0.75rem;

  path {
    display: block;
    fill: $custom-blue-20;
  }
}

.iconVisaDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.95rem;
  height: 1.1875rem;
}

.iconWarningOutlineDigitalGrey60 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(61, 61, 61);
  }

}

.iconWarningDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $text-base-dark;
  }
}

.iconWarningBaseDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $text-base-dark;
  }
}

.iconWarningSubduedDark {
  overflow: hidden;
  display: block;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    fill: $text-subdued-dark
  }
}

.iconAllEventsDigitalGrey60 {
  overflow: hidden;
  display: flex;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    fill: $digital-grey-60;
  }
}

.iconAllEventsDigitalGrey80 {
  overflow: hidden;
  display: flex;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    fill: $digital-grey-80;
  }
}

.iconAllEventsActionBase {
  overflow: hidden;
  display: flex;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    fill: $button-action-base;
  }
}

.iconExclusivesDigitalGrey60 {
  overflow: hidden;
  display: flex;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    fill: $digital-grey-60;
  }
}

.iconExclusivesDigitalGrey80 {
  overflow: hidden;
  display: flex;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    fill: $digital-grey-80;
  }
}

.iconExclusivesActionBase {
  overflow: hidden;
  display: flex;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    fill: $button-action-base;
  }
}

.iconMusicDigitalGrey60 {
  overflow: hidden;
  display: flex;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    fill: $digital-grey-60;
  }
}

.iconMusicDigitalGrey80 {
  overflow: hidden;
  display: flex;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    fill: $digital-grey-80;
  }
}

.iconMusicActionBase {
  overflow: hidden;
  display: flex;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    fill: $button-action-base;
  }
}

.iconRadioEmptyWhite {
  overflow: hidden;
  display: flex;
  width: 1rem;
  height: 1rem;

  rect {
    display: block;
    stroke: $white;
  }
}

.iconRadioEmptySubduedDark {
  overflow: hidden;
  display: flex;
  width: 1rem;
  height: 1rem;

  rect {
    display: block;
    stroke: $text-subdued-dark;
  }
}

.iconRadioFilledActionBase {
  overflow: hidden;
  display: flex;
  width: 1rem;
  height: 1rem;

  rect {
    display: block;
    stroke: $button-action-base;
  }
}

.iconSportsDigitalGrey60 {
  overflow: hidden;
  display: flex;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    fill: $digital-grey-60;
  }
}

.iconSportsDigitalGrey80 {
  overflow: hidden;
  display: flex;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    fill: $digital-grey-80;
  }
}

.iconSportsActionBase {
  overflow: hidden;
  display: flex;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    fill: $button-action-base;
  }
}

.iconComedyDigitalGrey60 {
  overflow: hidden;
  display: flex;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    fill: $digital-grey-60;
  }
}

.iconComedyDigitalGrey80 {
  overflow: hidden;
  display: flex;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    fill: $digital-grey-80;
  }
}

.iconComedyActionBase {
  overflow: hidden;
  display: flex;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    fill: $button-action-base;
  }
}
 
.iconTheaterDigitalGrey60 {
  overflow: hidden;
  display: flex;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    fill: $digital-grey-60;
  }
}

.iconTheaterDigitalGrey80 {
  overflow: hidden;
  display: flex;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    fill: $digital-grey-80;
  }
}

.iconTheaterActionBase {
  overflow: hidden;
  display: flex;
  width: 1rem;
  height: 1rem;

  path {
    display: block;
    fill: $button-action-base;
  }
}

.iconProfileFilledSubduedDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $text-subdued-dark;
  }
}

.iconProfileFilledCharcoal10 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $charcoal-10;
  }
}

.iconProfileThinSubduedDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;  
  padding: 3px; // Added padding as the exported icon looks too large
  box-sizing: border-box;

  path {
    display: block;
    fill: $text-subdued-dark;
  }
}

.iconProfileThinCharcoal10 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  padding: 3px; // Added padding as the exported icon looks too large
  box-sizing: border-box;

  path {
    display: block;
    fill: $charcoal-10;
  }
}

.iconPhoneMobileSubduedDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $text-subdued-dark;
  }
}

.iconPhoneMobileCharcoal10 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $charcoal-10;
  }
}

.iconTicketTiltSubduedDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $text-subdued-dark;
  }
}

.iconTicketTiltCharcoal10 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: $charcoal-10;
  }
}
