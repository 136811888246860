@import '../../../resources/scss/colors.scss';

.topContent {
  gap: 0.5rem;
  padding: 1rem 1rem calc(1rem - 1px) 1rem; // Account for 1px for bottom border
  border-bottom: 1px solid $digital-grey-10;
}

.phoneNumberRow {
  gap: 0.625rem;
}

.phoneNumberInput {
  width: 13.3125rem;
}

.bottomContent {
  gap: 0.5rem;
  padding: 1rem;
}

.acknowledgementText,
.acknowledgementText li {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  text-align: left;
  color: $text-subdued-dark;

  .acknowledgementTextList {
    list-style-type: disc;
    padding-left: 1.5rem;
    margin-bottom: 0;
  }
}
