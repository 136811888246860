@import '../../../resources/scss/colors.scss';
@import '../../../resources/scss/z-indexes.scss';

$border: 1px solid $core-blue-60;

.topNav {
  display: flex;
  width: 100%;
  background-color: $core-blue-70;
  position: fixed;
  top: var(--top-disclaimer-height, 0);
  z-index: $top-nav-z-index;
}

.topNavMobile {
  padding: 0.375rem 0.5rem calc(0.375rem - 1px) 0.5rem; // Account for 1px for bottom border
  border-bottom: $border;
}

.topNavMobile2Rows {
  flex-direction: column;

  .topContent {
    display: flex;
    padding: 0.375rem 0.5rem calc(0.375rem - 1px) 0.5rem; // Account for 1px for bottom border
    border-bottom: $border;
  }

  .bottomContent {
    display: flex;
    padding: 0.5rem;
  }

  .searchBar {
    display: flex;
    flex-grow: 1;
  }
}

.topNavDesktop {
  gap: 0.625rem;
  padding: 0.375rem 1rem;
  border-bottom: $border;

  .leftContent {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .rightContent {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    overflow: hidden;
  }

  .accountMenu {
    display: flex;
    width: 100%;
  }
}

.topNavLogoWithClose {
  align-items: center;
  gap: 0.625rem;
  height: 3.25rem;
  padding-left: 0.5rem;
  padding-right: 1rem;
  border-bottom: $border;
  box-sizing: border-box;

  @media (min-width: 1024px) {
    height: 3.125rem;
    padding-left: 1rem;
  }
}

.topNavLogoOnly {
  align-items: center;
  height: 3.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-bottom: $border;
  box-sizing: border-box;

  @media (min-width: 1024px) {
    height: 3.125rem;
    padding-left: 1rem;
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  flex-grow: 1;

  @media (min-width: 1024px) {
    padding-right: 0.5rem;
  }

  .logo {
    width: 7.75rem;
    height: 1.25rem;

    @media (min-width: 1024px) {
      width: 9.375rem;
      height: 1.5rem;
    }
  }
}

.menuButton {
  display: flex;
  align-self: center;

  .menuButtonIcon {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 0;
  }
}

.closeButton {
  width: 1.5rem;
  height: 1.5rem;
  background-color: transparent;

  &:active,
  &:hover {
    border-radius: 0.25rem;
    background-color: #ffffff33;
  }

  &:focus-visible {
    border-radius: 0.25rem;
    outline: 1px dashed $white;
    outline-offset: -1px;
  }
}
