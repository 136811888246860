@import '../../../resources/scss/colors.scss';

.priceBreakdownInfo {
  gap: 1rem;
  padding: 1rem;

  .row {
    justify-content: space-between;
    align-items: unset;
  }

  .priceBreakdown {
    gap: 0.5rem;
    padding: 0.75rem;
    background-color: $grey-10;
    border-radius: 0.5rem;
  }
}
