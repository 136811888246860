.radioButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  gap: 0.5rem;
  cursor: pointer;
}

.radioButtonLabel {
  display: flex;
  align-items: center;
}

.radioButtonIcon {
  flex-shrink: 0;
}
