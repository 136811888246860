@import '../../../resources/scss/colors.scss';

$border: 1px solid $digital-grey-10;
$border-radius: 0.5rem;

.topContent {
  gap: 0.5rem;
  padding: 1rem 1rem calc(1rem - 1px) 1rem; // Account for 1px for bottom border
  border-bottom: $border;
}

.rewardPaymentOptions {
  border-radius: $border-radius;
  background-color: $grey-10;

  .radioButton {
    gap: 0.75rem;
    padding: 1rem;

    &.applyRewardsRadioButton {
      padding-bottom: calc(1rem - 1px); // Account for 1px for bottom border
      border-bottom: 1px solid $grey-30;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    &.doNotApplyRewardsRadioButton {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }

  .radioButtonContent {
    gap: 0.75rem;
    padding: 0 0.75rem 0 0;

    .applyRewardsRadioButtonContent {
      row-gap: 0.5rem;
    }
  }

  .radioButtonText {
    line-height: 1.125rem;
  }

  .boldText {
    font-weight: 600;
    color: $text-base-dark;
  }

  .verticalDivider {
    height: 1.1875rem;
  }

  .rewardsTextInput {
    width: 3.5625rem;
  }
}
