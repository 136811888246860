@import '../../../resources/scss/colors.scss';

.eventInfoHeader {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;

  &.lightEventInfoHeader {
    background-color: $white;
    padding: 1rem 1rem calc(1rem - 1px) 1rem; // Account for 1px for bottom border
    border-bottom: 1px solid $digital-grey-10;

    .iconButton {
      background-color: transparent;

      &:hover {
        border-radius: 0.25rem;
        background-color: $custom-blue-10;
      }

      &:focus-visible {
        border-radius: 0.25rem;
        background-color: $custom-blue-10;
        outline: 1px dashed $interaction-blue-50;
        outline-offset: -1px;
      }

      &:active {
        border-radius: 0.25rem;
        background-color: $interaction-blue-10;
      }
    }
  }

  &.darkEventInfoHeader {
    background-color: $core-blue-70;
    padding: 0.75rem;

    .iconButton {
      background-color: transparent;

      &:active,
      &:hover {
        border-radius: 0.25rem;
        background-color: #ffffff33;
      }

      &:focus-visible {
        border-radius: 0.25rem;
        outline: 1px dashed $white;
        outline-offset: -1px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .eventInfoRow {
    display: flex;
    gap: 0.5rem;
  }

  .iconButton {
    display: flex;
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
    background-color: transparent;
  }

  .eventDateTimeAndVenue p {
    line-height: 1.25rem;
  }

  .truncatable {
    overflow: hidden;

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
