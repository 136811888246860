@import '../../../resources/scss/z-indexes.scss';

.dropdown {
  display: flex;
}

.dropdownToggle {
  display: flex;
}

.dropdownMenu {
  z-index: $top-nav-dropdown-menu-z-index;
  width: fit-content;
  transform: translate3d(11.375rem, var(--top-nav-height, 0), 0) !important; // Account for the height of the header. Do not account for the height of the top disclaimer.
}

.searchBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
}
