@import '../../../resources/scss/colors.scss';

$border-width: 0.1875rem;
$padding-bottom: 0.5rem;

.stepItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  padding: calc(0.75rem - #{$border-width}) 0 $padding-bottom 0; // Account for 3px for top border
  border-top-width: $border-width;
  border-top-style: solid;
  box-sizing: border-box;
}

.inCompletedStepItem {
  background-color: $white;
  border-top-color: $grey-20;
  padding-bottom: calc(#{$padding-bottom} - 1px); // Account for 1px for bottom border
  border-bottom: 1px solid $digital-grey-10;

  .stepItemLabel {
    color: $digital-grey-30;
  }
}

.completedStepItem,
.activeStepItem {
  background-color: $interaction-blue-10;
  border-top-color: $interaction-blue-50;

  .stepItemLabel {
    font-weight: 600;
  }
}

.activeStepItem {
  .stepItemLabel {
    color: $interaction-blue-50;
  }
}
