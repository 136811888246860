@import '../../../resources/scss/colors.scss';

.listingCard {
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  gap: 0.5rem;
  padding: 0.75rem;
  background-color: $white;
  border-radius: 0.75rem;

  &:hover {
    background-color: $custom-blue-10;
  }

  &:focus-visible {
    background-color: $custom-blue-10;
    outline: 0.125rem dashed $interaction-blue-50;
    outline-offset: -0.125rem;
  }

  &:active {
    background-color: $interaction-blue-10;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
  }

  .rightContentContainer {
    align-items: flex-end;
    flex-shrink: 0;

    p {
      text-align: right;
    }
  }

  .rowSubContainer {
    display: flex;
    align-items: baseline;
    gap: 0.25rem;
  }

  .lineHeight18px p {
    line-height: 1.125rem;
  }

  .lineHeight16px p {
    line-height: 1rem;
  }
}
